<template>
  <div class="mb-4">
      <CRow class="mb-0">
      	<CCol lg="2"> 
          <v-select 
            label="setting_name" 
            :options="taskList.data"
            :reduce="item => item.id"
            v-model="service_task_id"
            placeholder="Task"
          > 
          </v-select> 
        </CCol>
        <CCol lg="2"> 
          <v-select 
            label="setting_name" 
            :options="[
              {
                id: 'vehicle',
                setting_name: 'Vehicle'
              },
              {
                id: 'furnishing',
                setting_name: 'Furnishing'
              },
              {
                id: 'equipment',
                setting_name: 'Equipment'
              }
            ]"
            :reduce="item => item.id"
            v-model="asset_type"
            placeholder="Asset Type"
          > 
          </v-select>  
        </CCol>
        
        <CCol lg="2" v-if="asset_type == 'vehicle'">
          <v-select 
            label="plate_no" 
            :options="vehicleList.data"
            :reduce="item => item.id"
            v-model="vehicle_id"
            placeholder="Plate No."
          > 
          </v-select> 
        </CCol>
        <CCol lg="2" v-if="asset_type == 'furnishing'">
          <v-select 
            label="asset_no" 
            :options="furnishingList.data"
            :reduce="item => item.id"
            v-model="furnishing_id"
            placeholder="Asset No."
          > 
          </v-select> 
        </CCol>
        <CCol lg="2" v-if="asset_type == 'equipment'">
          <v-select 
            label="asset_no" 
            :options="equipmentList.data"
            :reduce="item => item.id"
            v-model="equipment_id"
            placeholder="Asset No."
          > 
          </v-select> 
        </CCol>
        <CCol lg="2" v-if="asset_type == '' || asset_type == null"> 
        </CCol>
        <CCol lg="4">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol lg="2">
          
          
        </CCol>
         <CCol lg="2">
          
          
        </CCol>
        
        <CCol lg="5"> 
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow> 
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
            return {
              asset_type: "",
              vehicle_id: "",
              furnishing_id: "",
              equipment_id: "",
              service_task_id: "", 

			
				taskList: {
					data: []
				},
            }
        },
      mounted() {
      
		this.getRenewalTask();
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
			asset_type: this.asset_type,
			vehicle_id: this.vehicle_id,
			furnishing_id: this.furnishing_id,
			equipment_id: this.equipment_id,
			service_task_id: this.service_task_id, 
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.asset_type = "";
          this.vehicle_id = "";
          this.furnishing_id = "";
          this.equipment_id = "";
          this.service_task_id = "";   
          this.date_from = "";
          this.date_to = "";   
          const data = {
            asset_type: this.asset_type,
			vehicle_id: this.vehicle_id,
			furnishing_id: this.furnishing_id,
			equipment_id: this.equipment_id,
			service_task_id: this.service_task_id, 
          }
          this.$emit("depot-search-query", data)
        },

      
	    getRenewalTask(){

	    	axios.get(config.api_path+'/reference/settings-list',{
			params:{
			setting_type:'service_task'
			}
			})
		.then(response => {
		this.taskList = response.data; 
		})

		},
      }
    }
</script>

<style scoped>

</style>
