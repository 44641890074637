<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Service Reminders ({{dataListAll.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
					v-if="config.getPermission('service_reminder').create"
					size="sm" style="margin-right: 10px" 
					color="success" @click="toggleModal()" 
					class="float-lg-right"><i class="fa fa-plus"></i>Add Service Reminder
					</CButton>
		      		<!-- <CButtonGroup size="sm" class="float-lg-right">
			          <CButton @click="toggleModal()" color="success"><i class="fa fa-plus"></i> Add User</CButton>
			          <CButton :class="showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        </CButtonGroup>  -->
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	     
		    <CCol lg="12">  
		    	<div class="table-responsive-sm table-responsive-md"	>
	      		<CTabs variant="pills" :active-tab="0">
	      			<CTab title="All">
				    	<br/>
						
					      	<CDataTable
							  :loading="isLoading"
						      	:items="dataListAll.data"
						      	:fields="fields"
								hover
								striped
								outlined
								
						    >  
							
						    	<template #service_task_id_label="{item}">
									 <td style="text-transform:capitalize;">
									 	{{item.service_task_id_label}}
				    					
			 						 </td>
								</template>
								<!-- next due column -->
								<template #next_due="{item}">
									 <td style="padding: 5px;">
									 	<div v-if="item.odometer_label != 'N/A' && item.asset_type=='vehicle'" >
									 		{{Math.sign(item.meter_next_due - item.odometer_label) == -1 ? Math.abs(item.meter_next_due - item.odometer_label) : (item.meter_next_due - item.odometer_label)}} 
									 		{{Math.sign(item.meter_next_due - item.odometer_label) == -1 ? "kilometers ago" : "kilometers remaining"}} <br>
											<small class="text-success">Due at: {{item.meter_next_due }} kilometers </small>
									 	</div>
									 	<div v-else>
											<span v-if="item.asset_type=='vehicle'">{{ item.meter_next_due }} kilometers remaining</span>
									 	</div>
									 	<div v-if="item.due_date !=null">
									 		{{item.renewal_text}} <br>
											 <small style="margin-top:10px;" class="text-info">Due on: {{item.time_next_due}}</small>
									 	</div>
										 <div v-else>
											
									 	</div>
			 						 </td>
								</template>
								<!-- reminder column -->
								<template #reminder="{item}">
									 <td style="padding: 5px; text-transform:capitalize;">
									 	<div v-if="item.asset_type == 'vehicle'">
									 		Every {{item.meter_reminder}} kilometer/s 
									 		<CBadge   
					    						v-if="(item.meter_next_due - item.odometer_label) <= item.meter_treshold && (item.meter_next_due - item.odometer_label) >= 0"
												color="warning">
												Due Soon
											</CBadge>
											<CBadge   
					    						v-if="(item.meter_next_due - item.odometer_label) <= item.meter_treshold && (item.meter_next_due - item.odometer_label) < 0"
												color="danger">
												Overdue
											</CBadge>
									 	</div>
									 	<div v-else>
									 		
									 	</div>
										<br>
									 	<div v-if="item.time_based_interval_value != null && item.time_based_interval_value != ''">
									 		Every {{item.time_reminder}} 
									 		<CBadge   
					    						v-if="item.time_due_soon == 'due soon'"
												color="warning">
												Due Soon
											</CBadge>
											<CBadge   
					    						v-if="item.time_due_soon == 'over due'"
												color="danger">
												Overdue
											</CBadge>
									 	</div>
									 	<div v-else>
									 		
									 	</div>
			 						 </td>
								</template>
						      	<template #action="{item}">
							        <td style="padding: 5px; width: 100px">
							        	<CDropdown 
							                toggler-text="Select"  
											size="sm"
							                color="info"
							            >
							            	<CDropdownItem 	v-if="config.getPermission('service_reminder').view" @click="viewModal(item)">View</CDropdownItem> 
							            	<CDropdownItem 	v-if="config.getPermission('service_reminder').update" @click="updateModal(item)">Edit</CDropdownItem> 
							                <!-- <CDropdownItem @click="updateModal(item)">
							                	<div>
							                		<div>Notify</div>  
							                		<CBadge 
							                		block
							                		class="block text-white" 
													color="warning">{{item.notify_date}}</CBadge> 
							                	</div>
							                </CDropdownItem> -->
							                <CDropdownItem 	v-if="config.getPermission('service_reminder').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
							            </CDropdown>
							        </td>
						      	</template>
						    </CDataTable> 
						    <CPagination
					          	:active-page.sync="currentPageAll"
					          	:pages="Math.ceil(dataListAll.total / 20)"
					          	:activePage="currentPageAll"
					          	@update:activePage="updatePageAll"
					        />	
				    </CTab>
				    <CTab title="Overdue">
				    	<br/>
						
					      	<CDataTable
							  :loading="isLoading"
						      	:items="dataListOverDue.data"
						      	:fields="fields"
								hover
								striped
								outlined
								
						    >  
						    	<template #service_task_id_label="{item}">
									 <td style="text-transform:capitalize;">
									 	{{item.service_task_id_label}}
				    					<!-- <CBadge  
				    						v-if="item.renewal_badge != null"
											:color="item.renewal_badge">
											{{item.renewal_text}}
										</CBadge> -->
			 						 </td>
								</template>
								<template #next_due="{item}">
									 <td style="padding: 5px;">
									 	<div v-if="item.odometer_label != 'N/A' && item.asset_type=='vehicle'" >
									 		{{Math.sign(item.meter_next_due - item.odometer_label) == -1 ? Math.abs(item.meter_next_due - item.odometer_label) : (item.meter_next_due - item.odometer_label)}} 
									 		{{Math.sign(item.meter_next_due - item.odometer_label) == -1 ? "kilometers ago" : "kilometers remaining"}} <br>
											<small class="text-success">Due at: {{item.meter_next_due }} kilometers </small>
									 	</div>
									 	<div v-else>
											<span v-if="item.asset_type=='vehicle'">{{ item.meter_next_due }} kilometers remaining</span>
									 	</div>
									 	<div v-if="item.due_date !=null">
									 		{{item.renewal_text}} <br>
											 <small style="margin-top:10px;" class="text-info">Due on: {{item.time_next_due}}</small>
									 	</div>
										 <div v-else>
											
									 	</div>
			 						 </td>
								</template>
								<!-- reminder column -->
								<template #reminder="{item}">
									 <td style="padding: 5px; text-transform:capitalize;">
									 	<div v-if="item.asset_type == 'vehicle'">
									 		Every {{item.meter_reminder}} kilometers 
									 		<CBadge   
					    						v-if="(item.meter_next_due - item.odometer_label) <= item.meter_treshold && (item.meter_next_due - item.odometer_label) >= 0"
												color="warning">
												Due Soon
											</CBadge>
											<CBadge   
					    						v-if="(item.meter_next_due - item.odometer_label) <= item.meter_treshold && (item.meter_next_due - item.odometer_label) < 0"
												color="danger">
												Overdue
											</CBadge>
									 	</div>
									 	<div v-else>
									 		
									 	</div>
										<br>
									 	<div v-if="item.time_based_interval_value != null && item.time_based_interval_value != ''">
									 		Every {{item.time_reminder}} 
									 		<CBadge   
					    						v-if="item.time_due_soon == 'due soon'"
												color="warning">
												Due Soon
											</CBadge>
											<CBadge   
					    						v-if="item.time_due_soon == 'over due'"
												color="danger">
												Overdue
											</CBadge>
									 	</div>
									 	<div v-else>
									 		
									 	</div>
			 						 </td>
								</template>
						      	<template #action="{item}">
							        <td style="padding: 5px; width: 100px">
							        	<CDropdown 
							                toggler-text="Select"  
											size="sm"
							                color="info"
							            >
							            	<CDropdownItem 	v-if="config.getPermission('service_reminder').view" @click="viewModal(item)">View</CDropdownItem> 
							            	<CDropdownItem v-if="config.getPermission('service_reminder').update" @click="updateModal(item)">Edit</CDropdownItem> 
							                <CDropdownItem @click="updateModal(item)">
							                	<div>
							                		<div>Notify</div>  
							                		<CBadge 
							                		block
							                		class="block text-white" 
													color="warning">{{item.notify_date}}</CBadge> 
							                	</div>
							                </CDropdownItem>
							                <CDropdownItem v-if="config.getPermission('service_reminder').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
							            </CDropdown>
							        </td>
						      	</template>
						    </CDataTable> 
						    <CPagination
					          	:active-page.sync="currentPageOverDue"
					          	:pages="Math.ceil(dataListOverDue.total / 20)"
					          	:activePage="currentPageOverDue"
					          	@update:activePage="updatePageOverDue"
					        />	
				    </CTab>
				    <CTab title="Due Soon">
				    	<br/>
						
					      	<CDataTable
							  :loading="isLoading"
						      	:items="dataListDueSoon.data"
						      	:fields="fields"
								hover
								striped
								outlined
								
								
						    >  
						    	<template #service_task_id_label="{item}">
									 <td style="text-transform:capitalize;">
									 	{{item.service_task_id_label}}
				    					
			 						 </td>
								</template>
								<template #next_due="{item}">
									 <td style="padding: 5px;">
									 	<div v-if="item.odometer_label != 'N/A' && item.asset_type=='vehicle'" >
									 		{{Math.sign(item.meter_next_due - item.odometer_label) == -1 ? Math.abs(item.meter_next_due - item.odometer_label) : (item.meter_next_due - item.odometer_label)}} 
									 		{{Math.sign(item.meter_next_due - item.odometer_label) == -1 ? "kilometers ago" : "kilometers remaining"}} <br>
											<small class="text-success">Due at: {{item.meter_next_due }} kilometers </small>
									 	</div>
									 	<div v-else>
											<span v-if="item.asset_type=='vehicle'">{{ item.meter_next_due }} kilometers remaining</span>
									 	</div>
									 	<div v-if="item.due_date !=null">
									 		{{item.renewal_text}} <br>
											 <small style="margin-top:10px;" class="text-info">Due on: {{item.time_next_due}}</small>
									 	</div>
										 <div v-else>
											
									 	</div>
			 						 </td>
								</template>
								<!-- reminder column -->
								<template #reminder="{item}">
									 <td style="padding: 5px; text-transform:capitalize;">
									 	<div v-if="item.asset_type == 'vehicle'">
									 		Every {{item.meter_reminder}} kilometers 
									 		<CBadge   
					    						v-if="(item.meter_next_due - item.odometer_label) <= item.meter_treshold && (item.meter_next_due - item.odometer_label) >= 0"
												color="warning">
												Due Soon
											</CBadge>
											<CBadge   
					    						v-if="(item.meter_next_due - item.odometer_label) <= item.meter_treshold && (item.meter_next_due - item.odometer_label) < 0"
												color="danger">
												Overdue
											</CBadge>
									 	</div>
									 	<div v-else>
									 		
									 	</div>
										<br>
									 	<div v-if="item.time_based_interval_value != null && item.time_based_interval_value != ''">
									 		Every {{item.time_reminder}} 
									 		<CBadge   
					    						v-if="item.time_due_soon == 'due soon'"
												color="warning">
												Due Soon
											</CBadge>
											<CBadge   
					    						v-if="item.time_due_soon == 'over due'"
												color="danger">
												Overdue
											</CBadge>
									 	</div>
									 	<div v-else>
									 		
									 	</div>
			 						 </td>
								</template>
						      	<template #action="{item}">
							        <td style="padding: 5px; width: 100px">
							        	<CDropdown 
							                toggler-text="Select"  
											size="sm"
							                color="info"
							            >
							            	<CDropdownItem v-if="config.getPermission('service_reminder').view" @click="viewModal(item)">View</CDropdownItem> 
							            	<CDropdownItem v-if="config.getPermission('service_reminder').update" @click="updateModal(item)">Edit</CDropdownItem> 
							                <CDropdownItem @click="updateModal(item)">
							                	<div>
							                		<div>Notify</div>  
							                		<CBadge 
							                		block
							                		class="block text-white" 
													color="warning">{{item.notify_date}}</CBadge> 
							                	</div>
							                </CDropdownItem>
							                <CDropdownItem v-if="config.getPermission('service_reminder').delete" @click="deleteRow(item)">Delete</CDropdownItem> 
							            </CDropdown>
							        </td>
						      	</template>
						    </CDataTable> 
						    <CPagination
					          	:active-page.sync="currentPageDueSoon"
					          	:pages="Math.ceil(dataListDueSoon.total / 20)"
					          	:activePage="currentPageDueSoon"
					          	@update:activePage="updatePageDueSoon"
					        />	
				    </CTab>
				</CTabs>
				</div>
	      	</CCol>
	    </CRow>

		
		<!-- CREATE/UPDATE -->
	    <CModal :title="'Service Reminder'" :show.sync="formModal" color="info" size="lg">
           	<form @submit.prevent="submit"> 
				  
				<CRow>
					<CCol lg="8">
						<div class="form-group">
							<label style="font-size:13px" for="asset_type">Asset Type<span class="text-danger"> *</span>
							</label> 
							<CRow>
								<CCol lg="3">
									<CInputRadioGroup  
					                    :options="[
					                   	  {
												value: 'vehicle', 
												label: 'Vehicle'
									        }
									       
					                    ]" 
					                    :checked.sync="dataParams.asset_type" 
					                    :inline="true" 
					                    required="true"
					                />
								</CCol>
								<CCol lg="3">
									<CInputRadioGroup  
					                    :options="[
					                   	  {
												value: 'equipment', 
												label: 'Equipment'
									        }
									       
					                    ]" 
					                    :checked.sync="dataParams.asset_type" 
					                    :inline="true" 
					                    required="true"
					                />
								</CCol>
								<CCol lg="3">
									<CInputRadioGroup  
					                    :options="[
					                   	  {
												value: 'furnishing', 
												label: 'Furnishing'
									        }
									       
					                    ]" 
					                    :checked.sync="dataParams.asset_type" 
					                    :inline="true" 
					                    required="true"
					                />
								</CCol>
							</CRow>
			            </div>
					</CCol> 
				</CRow>  
				<CRow> 
					<CCol lg="12"> 
						<label style="font-size:13px" v-if="dataParams.asset_type !== 'vehicle'">Asset No. <span class="text-danger">*</span></label>
						<label style="font-size:13px"  v-else >Vehicle <span class="text-danger">*</span></label>
						
						<v-select 
							label="asset_no" 
							:options="furnishingList.data"
							:reduce="item => item.id"
							placeholder="-Select-" 
							v-if="dataParams.asset_type == 'furnishing'" 
							v-model="dataParams.furnishing_id"
						>
							<template #search="{attributes, events}" v-if="dataParams.asset_type == 'furnishing'">
								<input
									class="vs__search"
									:required="!dataParams.furnishing_id && dataParams.asset_type == 'furnishing'"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
						<v-select 
							label="asset_no" 
							:options="equipmentList.data"
							:reduce="item => item.id"
							placeholder="-Select-"  
							v-model="dataParams.equipment_id"
							v-if="dataParams.asset_type == 'equipment'" 
						>
							<template #search="{attributes, events}" v-if="dataParams.asset_type == 'equipment'">
								<input
									class="vs__search"
									:required="!dataParams.equipment_id && dataParams.asset_type == 'equipment'"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>  
						<v-select 
							label="plate_no" 
							:options="vehicleList.data"
							:reduce="item => item.id"
							placeholder="-Select-"  
							v-model="dataParams.vehicle_id"
							v-if="dataParams.asset_type == 'vehicle'" 
						>
							<template #search="{attributes, events}" v-if="dataParams.asset_type == 'vehicle'">
								<input
									class="vs__search"
									:required="!dataParams.vehicle_id && dataParams.asset_type == 'vehicle'"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>  
					</CCol>
				</CRow>
           		<CRow class="mb-3 mt-3">
					   
					<CCol lg="12">
						<label style="font-size:13px">
							Service Task
							<span class="text-danger">*</span>
						</label>
						<v-select 
							label="setting_name" 
							:options="taskList.data"
							:reduce="item => item.id"
							placeholder="-Select- " 
							v-model="dataParams.service_task_id" 
						>
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!dataParams.service_task_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>  
					</CCol> 
				</CRow>
				<CRow class="mb-2" v-if="dataParams.asset_type == 'vehicle'" >
					   
					<CCol lg="6">
						<label style="font-size:13px">
							Meter Based Interval 
							<span class="text-danger">*</span>
							<i class="fa fa-question-circle ml-2" title="Set a reminder based on a meter reading or usage (For example. Change oil every 2000kilometers)." ></i>
						</label>
						<CInput 
		                  placeholder=""
		                  v-model="dataParams.meter_based_interval" 
		                  type="number"
		                />
		                <!-- <div class="text-danger" style="margin-top: -15px; font-size:11px">Repeat based on meter reading (e.g. Change oil every 2000 odometer).</div> -->
					</CCol> 
					<CCol lg="6">
						<label style="font-size:13px">
							Meter Due Soon Threshold
							<i class="fa fa-question-circle ml-2" title="Number of miles/kilometers/hours in advance you consider this reminder to be due soon" ></i>
						</label>
						<CInput 
						
		                  v-model="dataParams.meter_treshold" 
		                  type="number"
		                />
		                <!-- <div class="text-danger" style="margin-top: -15px; font-size:11px">Number of miles/kilometers/hours in advance you consider this reminder to be "due soon"</div> -->
					</CCol> 
				</CRow>
				<!-- <CRow class="mb-2" v-if="dataParams.asset_type == 'vehicle'" > -->
				<CRow class="mb-2" >
					<CCol lg="3" > 
						<label style="font-size:13px">
							Time Based Interval
							<span class="text-danger" v-if="dataParams.asset_type != 'vehicle'">*</span>
							<i class="fa fa-question-circle ml-2" title="Set a reminder based on a time interval (For example. Change oil every 2 months)" ></i>
							
						</label>
						<CInput   
							placeholder="Every"
							v-model="dataParams.time_based_interval_value" 
							type="number"
							:required="dataParams.asset_type != 'vehicle'"
						/>
						
					</CCol>  
					<CCol lg="3">
						<label style="font-size:13px">
							&nbsp;
						</label>
						<v-select 
							label="setting_name" 
							:options="[
								{
									id: 'days',
									setting_name: 'Days'
								},
								{
									id: 'weeks',
									setting_name: 'Weeks'
								},
								{
									id: 'months',
									setting_name: 'Months'
								},
								{
									id: 'years',
									setting_name: 'Years'
								}
							]"
							:reduce="item => item.id"
							placeholder="-Select- " 
							v-model="dataParams.time_based_interval_unit" 
							:required="dataParams.asset_type != 'vehicle'"
						>
							
						</v-select>  
					</CCol> 
					<CCol lg="3">
						<label style="font-size:13px">
							Time Due Soon Threshold<i class="fa fa-question-circle ml-2" title="The amount of time in advance you consider this reminder to be due soon. (For example: Every 2 weeks)" ></i>
						</label>
						<CInput   
							placeholder="Every"
							v-model="dataParams.time" 
							type="number"	
											
						/>					
					</CCol> 
					<CCol lg="3">
						<label style="font-size:13px">
							&nbsp;
						</label>
						<v-select 
							label="setting_name" 
							:options="[
								{
									id: 'days',
									setting_name: 'Days'
								},
								{
									id: 'weeks',
									setting_name: 'Weeks'
								},
								{
									id: 'months',
									setting_name: 'Months'
								},
								{
									id: 'years',
									setting_name: 'Years'
								}
							]"
							:reduce="item => item.id"
							v-model="dataParams.treshold_unit" 
						>
								
						</v-select>  
						
					</CCol> 	
				</CRow>
				<CRow>
					<CCol lg="12">
						<div class="form-group"> 
							<label style="font-size:13px">Next Due Date 
								<span class="text-danger" v-if="dataParams.asset_type != 'vehicle'">*</span>
							</label>
							
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.due_date"
								placeholder="" 
								:typeable="dataParams.asset_type != 'vehicle'"
								:required="dataParams.asset_type != 'vehicle'"
							>
							</Datepicker>  
						</div>
					</CCol> 
					<!-- <CCol lg="12" v-if="dataParams.asset_type == 'vehicle'">
						<div class="form-group"> 
							<label style="font-size:13px">Next Due Date 
							</label>
							
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.due_date"
								placeholder="" 
								
							>
							</Datepicker>  
						</div>
					</CCol> 

					<CCol lg="12" v-else>
						<div class="form-group"> 
							<label style="font-size:13px">Next Due Date 
							</label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.due_date"
								:typeable="true" 
								required 
								
							>
							</Datepicker>  
						</div>
					</CCol>  -->
					<CCol lg="12">
						<div class="text-info mb-2" style="margin-top: -15px; font-size:11px">
							Manually set the due date for the next reminder.
						</div>
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<label style="font-size:13px">
							Notified Email  <span class="text-danger">* </span>
						</label>
						<CInput 
		                  placeholder=""
		                  v-model="dataParams.email" 
						  required
		                />
		                <div class="text-info mb-2" style="margin-top: -15px; font-size:11px">Enter multiple email addresses, separated by comma.</div>
					</CCol> 
				</CRow>
			
				<CRow class="mb-3">
					   
					<CCol lg="12">
						<label style="font-size:13px">
							Remarks 
						</label>
						<CInput  
		                  placeholder=""
		                  v-model="dataParams.remarks"  
		                /> 
					</CCol> 
				</CRow>
				 
				 
				 <span class="text-danger">{{message}}</span>	
	            <div slot="footer" class="w-100">
					<CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	                
	                
	            </div>
				
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>

		<!-- VIEWING DETAILS -->
        <CModal title="Service Reminder" :show.sync="showViewModal" color="info" size="lg"> 
			
				<CRow class="mb-2">
	        		<!-- <CCol lg="4">
	        			Service Task:
	        		</CCol> -->
	        		<CCol lg="12">
	        			<h5>{{dataParams.service_task_id_label}} </h5>
	        		</CCol>
	        	</CRow>

        		<CRow class="mb-2">
	        		<CCol lg="4">
	        			Asset Type:
	        		</CCol>
	        		<CCol lg="8" style="text-transform: capitalize;">
	        			{{dataParams.asset_type}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4" v-if="dataParams.asset_type=='vehicle'">
	        			Plate No.:
	        		</CCol>
	        		<CCol lg="4" v-else>
	        			Asset No.:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.asset_no_label}}
	        		</CCol>
	        	</CRow>
	        	<!-- <CRow class="mb-2">
	        		<CCol lg="4">
	        			Service Task:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.service_task_id_label}}
	        		</CCol>
	        	</CRow> -->
	        	<CRow class="mb-2" v-if="dataParams.asset_type == 'vehicle'">
	        		<CCol lg="4">
	        			Meter Based Interval:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.meter_based_interval}}
	        		</CCol>
					<CCol lg="4">
	        			
	        		</CCol>
	        		<CCol lg="8">
	        			<small> Due soon threshold: {{dataParams.meter_treshold == null || dataParams.meter_treshold == "" ? "N/A" : dataParams.meter_treshold}} </small>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2" >
	        		<CCol lg="4">
	        			Time Based Interval:
	        		</CCol>
	        		<CCol lg="8" v-if="dataParams.time_based_interval_value != null && dataParams.time_based_interval_value != ''">
	        			{{dataParams.time_based_interval_value}} {{dataParams.time_based_interval_unit}}
	        		</CCol>
					<CCol lg="8" v-else>
	        			-
	        		</CCol>
	        		<CCol lg="4">
	        			
	        		</CCol>
	        		<CCol lg="8" v-if="dataParams.time == '' || dataParams.time == null || dataParams.treshold_unit == '' || dataParams.treshold_unit == null">
	        			
	        		</CCol>
	        		<CCol lg="8" v-else>
	        			<small> Due Soon Thresold: {{dataParams.time}} {{dataParams.treshold_unit}} </small>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Next Due Date:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.due_date_label}}
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Notified Email:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.email}}
	        		</CCol>
	        	</CRow>
	        	<!-- <CRow class="mb-2" v-if="dataParams.asset_type == 'vehicle'">
	        		<CCol lg="4">
	        			Meter Threshold:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.meter_treshold == null || dataParams.meter_treshold == "" ? "N/A" : dataParams.meter_treshold}}
	        		</CCol>
	        	</CRow> -->
	        	<!-- <CRow class="mb-2">
	        		<CCol lg="4">
	        			Time Threshold:
	        		</CCol>
	        		<CCol lg="8" v-if="dataParams.time == '' || dataParams.time == null || dataParams.treshold_unit == '' || dataParams.treshold_unit == null">
	        			N/A
	        		</CCol>
	        		<CCol lg="8" v-else>
	        			{{dataParams.time}} {{dataParams.treshold_unit}}
	        		</CCol>
	        	</CRow> -->
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Remarks:
	        		</CCol>
	        		<CCol lg="8">
	        			{{dataParams.remarks == "" || dataParams.remarks === null ? "N/A" : dataParams.remarks}}
	        		</CCol>
	        	</CRow>
        	
        	<div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
                    Close
                </CButton> 
                
            </div>
        	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  

export default {
	mounted(){ 
		this.getData();
		this.getDataDueSoon(); 
		this.getDataOverDue(); 
		this.getVehicleList();
		this.getEquipment();
		this.getFurnishing();
		this.getRenewalTask();
	},
	data(){
		return{
			config,
			title:'',
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataListDueSoon: [],
			dataListOverDue: [],
			depotName: "",
			email: "",
			dataParams: {
				asset_type: "vehicle",
				vehicle_id: "",
				furnishing_id: "",
				equipment_id: "", 
				email: "",
				treshold_unit: "",
				time: "",
				service_task_id: "",
				due_date: "", 
				meter_based_interval: "",
				time_based_interval_value: "",
				time_based_interval_unit: "",
				meter_treshold: "",
				remarks: "",
				meter_interval_trigger: 0
			},
			fields: [
				{
					key: 'asset_type', 
					label: 'Asset Type'
				},
				{
					key: 'asset_no_label', 
					label: 'Asset No.'
				},
				// {
				// 	key: 'type', 
				// 	label: 'Type'
				// }, 
				{
					key: 'service_task_id_label', 
					label: 'Service Task'
				},
				{
					key: 'odometer_label', 
					label: 'Present Odometer'
				},
				{
					key: 'next_due', 
					label: 'Next Due'
				},
				{
					key: 'reminder', 
					label: 'Reminder'
				}, 
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPageAll: 1,
			currentPageDueSoon: 1,
			currentPageOverDue: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			isLoading: false,
			filter: {
				asset_type: "",
              vehicle_id: "",
              furnishing_id: "",
              equipment_id: "",
              service_task_id: "", 
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			userTypeList: {
				data: []
			},
			companyList: {
				data:[]
			},
			vehicleList: {
				data:[]
			},
			equipmentList: {
				data: []
			},
			furnishingList: {
				data: []
			},
			taskList: {
				data: []
			},
			message: ""
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		 getBadge (status) {
    	return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    },
		updatePageDueSoon(data){
			this.currentPageDueSoon = data;
			this.getDataDueSoon();
		}, 
		updatePageAll(data){
			this.currentPageAll = data;
			this.getData();
		}, 
		updatePageOverDue(data){
			this.currentPageOverDue = data;
			this.getDataOverDue();
		}, 

	    toggleModal(){
	    	this.formModal = !this.formModal;
			this.title = "Add User";
			this.dataParams = {
				asset_type: "vehicle",
				vehicle_id: "",
				furnishing_id: "",
				equipment_id: "", 
				email: "",
				treshold_unit: "",
				time: "",
				service_task_id: "",
				due_date: "", 
				meter_based_interval: "",
				time_based_interval_value: "",
				time_based_interval_unit: "",
				meter_treshold: "",
				remarks: "",
				meter_interval_trigger: 0
			}

	    },

	    submit(){
	    	var ax = {};
	    	this.message = "";
	    	var temp_date = this.dataParams.due_date;
	    	this.dataParams.due_date = moment(this.dataParams.due_date).startOf('day').unix();
	    	var current_date = moment(new Date()).startOf('day').unix();
	    	// if(current_date == this.dataParams.due_date){
	    	// 	this.message = "Can't select today's date";
	    	// 	this.dataParams.due_date = temp_date;
	    	// 	return;
	    	// } 
	    	if(this.dataParams.furnishing_id == ""){
	    		this.dataParams.furnishing_id = null
	    	}
	    	if(this.dataParams.vehicle_id == ""){
	    		this.dataParams.vehicle_id = null
	    	}
	    	if(this.dataParams.equipment_id == ""){
	    		this.dataParams.equipment_id = null
	    	}
	    	if(this.dataParams.meter_based_interval == ""){
	    		this.dataParams.meter_based_interval = null
	    	}
	    	if(this.dataParams.time_based_interval_value == ""){
	    		this.dataParams.time_based_interval_value = null
	    	}
	    	if(this.dataParams.meter_treshold == ""){
	    		this.dataParams.meter_treshold = null
	    	}


	    	if(this.dataParams.asset_type == "vehicle"){ 
	    		var vehicle = this.vehicleList.data.filter((value, index)=>{
	    			return value.id == this.dataParams.vehicle_id;
	    		})
	    		if(vehicle.length > 0){
	    			vehicle = vehicle[0];
	    			if(vehicle.odo_reading && this.dataParams.meter_based_interval != this.selectedRow.meter_based_interval){
	    				this.dataParams.current_odo = vehicle.odo_reading.reading;
	    				this.dataParams.meter_interval_trigger = vehicle.odo_reading.reading;
	    			}
	    		} 
			}
			var time_next_due = null;

			time_next_due = moment.unix(this.dataParams.due_date);
				var pre_due_date = moment(time_next_due).subtract(this.dataParams.time, this.dataParams.treshold_unit)
				pre_due_date = pre_due_date.unix();
				this.dataParams.pre_due_date = pre_due_date;
				this.dataParams.post_due_date = this.dataParams.due_date
			// if(this.dataParams.asset_type == 'vehicle'){

			// 	time_next_due = moment.unix(this.dataParams.due_date).add(this.dataParams.time_based_interval_value, this.dataParams.time_based_interval_unit)
			// 	this.dataParams.post_due_date = time_next_due.unix();
			// 	var pre_due_date = moment(time_next_due).subtract(this.dataParams.time, this.dataParams.treshold_unit)
			// 	pre_due_date = pre_due_date.unix();
			// 	this.dataParams.pre_due_date = pre_due_date;
			// }
			// else{
			// 	time_next_due = moment.unix(this.dataParams.due_date);
			// 	var pre_due_date = moment(time_next_due).subtract(this.dataParams.time, this.dataParams.treshold_unit)
			// 	pre_due_date = pre_due_date.unix();
			// 	this.dataParams.pre_due_date = pre_due_date;
			// 	this.dataParams.post_due_date = this.dataParams.due_date
			// }
 

	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/serviceReminder/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/serviceReminder", this.dataParams)
	    	}
	    	ax.then(response => {
	    		if(response.data.status == "failed"){
					this.message = response.data.message;
					this.dataParams.due_date = temp_date;
					return;
				}
				this.message = ""
	    // 		if(response.data.status == 'failed'){
	    // 			Swal.fire({
					// 	title: 'Error!',
					// 	text: response.data.message,
					// 	icon: 'error', 
					// })
					// return;
	    // 		}
	    		var text = 'Service reminder successfully added!';
	    		if(this.editMode){
	    			text = 'Service reminder successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					asset_type: "vehicle",
					vehicle_id: "",
					furnishing_id: "",
					equipment_id: "", 
					email: "",
					treshold_unit: "",
					time: "",
					service_task_id: "",
					due_date: "", 
					meter_based_interval: "",
					time_based_interval_value: "",
					time_based_interval_unit: "",
					meter_treshold: "",
					remarks: "",
					meter_interval_trigger: 0
				}
	    		this.getData(); 
	    		this.getDataDueSoon();
	    		this.getDataOverDue();  
	    	})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData(); 
	    	this.getDataDueSoon();
	    	this.getDataOverDue();  
	    },

	    getData(){ 

			this.isLoading = true;

	    	var service_task_id = this.filter.service_task_id;
	    	if(service_task_id == "all" || service_task_id == null || service_task_id == ""){
	    		service_task_id = ""
	    	}

	    	var vehicle_id = this.filter.vehicle_id;
	    	if(vehicle_id == "all" || vehicle_id == null || vehicle_id == ""){
	    		vehicle_id = "";
	    	}

	    	var furnishing_id = this.filter.furnishing_id;
	    	if(furnishing_id == "all" || furnishing_id == null || furnishing_id == ""){
	    		furnishing_id = "";
	    	}

	    	var equipment_id = this.filter.equipment_id;
	    	if(equipment_id == "all" || equipment_id == null || equipment_id == ""){
	    		equipment_id = "";
	    	}

	    	var asset_type = this.filter.asset_type;
	    	if(asset_type == "all" || asset_type == null || asset_type == ""){
	    		asset_type = "";
	    		vehicle_id = ""
				furnishing_id = ""
				equipment_id = ""

	    	}
	    	else{
	    		if(asset_type == "vehicle"){
	    			furnishing_id = ""
					equipment_id = ""
	    		}
	    		if(asset_type == "furnishing"){
	    			vehicle_id = ""
					equipment_id = ""
	    		}
	    		if(asset_type == "equipment"){
	    			vehicle_id = ""
					furnishing_id = ""
	    		}
	    	}

	    	var vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = "";
	    	}

	    	var date_from = this.filter.date_from;
			if(date_from != ""){
	    		date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var date_to = this.filter.date_to;
	    	if(date_to != ""){
	    		date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		date_to = date_to + 86399; 
	    	}

	    	axios.get(config.api_path+'/serviceReminder?service_task_id='+service_task_id+'&asset_type='+asset_type+'&vehicle_id='+vehicle_id+'&furnishing_id='+furnishing_id+'&equipment_id='+equipment_id+'&page='+this.currentPageAll+'&limit=20')
	    	.then(response => {
	    		this.dataListAll = response.data; 
	    		this.dataListAll.data = this.dataListAll.data.map((value, index)=>{
	    			value.vehicle_maker = "N/A";
	    			value.vehicle_type = "N/A";
	    			// if(value.vehicle){
	    			// 	value.vehicle_label = value.vehicle.plate_no;
	    			// 	value.vehicle_maker = value.vehicle.maker_model ? value.vehicle.maker_model.setting_name : "N/A"
	    			// 	value.vehicle_type = value.vehicle.vehicle_type ? value.vehicle.vehicle_type.setting_name : "N/A"
	    			// }
	    			// else{
	    			// 	value.vehicle_label = "N/A";
	    			// } 
	    			value.odometer_label = value.odometer_label ?? '0';
					

	    			if(value.asset_type == "vehicle"){
	    				value.type = value.vehicle.vehicle_type.setting_name;
	    				value.asset_no_label = value.vehicle.plate_no;
	    				if(value.vehicle.odometer){
	    					if(value.vehicle.odometer.length > 0){
	    						value.odometer_label = value.vehicle.odometer[value.vehicle.odometer.length - 1].reading;
	    					}
	    				}
	    			}

	    			if(value.asset_type == "furnishing"){
	    				value.type = value.furnishing.type;
	    				value.asset_no_label = value.furnishing.asset_no;
	    			}

	    			if(value.asset_type == "equipment"){
	    				value.type = value.equipment.type;
	    				value.asset_no_label = value.equipment.asset_no;
	    			}

	    			value.service_task_id_label = "N/A";
	    			if(value.service_task_id && value.service_task_id != ""){
	    				value.service_task_id_label = value.service_task.setting_name;
	    			} 

	    			value.due_date_label = "N/A";
	    			if(value.due_date && value.due_date != ""){
	    				value.due_date_label = moment.unix(value.due_date).format('MMMM DD, YYYY')
	    			} 
	    			var unit = "days";
	    			if(value.treshold_unit == "weeks"){
	    				unit = "weeks";
	    			}
	    			if(value.treshold_unit == "months"){
	    				unit = "months";
	    			}
	    			if(value.treshold_unit == "years"){
	    				unit = "years";
	    			}

	    			value.notify_date = moment.unix(value.due_date).subtract(value.time, unit).format('MMMM DD, YYYY');


	    			value.meter_next_due = "N/A";
	    			value.meter_reminder = "N/A";
	    			if(value.asset_type == "vehicle"){ 
	    				value.meter_next_due = value.current_odo + value.meter_based_interval;
	    				value.meter_reminder = value.meter_based_interval;
	    			} 

	    			var interval_unit = "days";
	    			if(value.time_based_interval_unit == "weeks"){
	    				interval_unit = "weeks";
	    			}
	    			if(value.time_based_interval_unit == "months"){
	    				interval_unit = "months";
	    			}
	    			if(value.time_based_interval_unit == "years"){
	    				interval_unit = "years";
	    			}

	    			value.meter_next_due = value.current_odo + value.meter_based_interval;
	    			if(value.asset_type == "vehicle"){ 
	    				value.meter_next_due = value.current_odo + value.meter_based_interval;
	    			}
					
	    			value.time_next_due = "N/A";
					value.time_next_due = moment.unix(value.due_date).format('MMMM DD, YYYY');
	    			// value.time_next_due = moment.unix(value.due_date).add(value.time_based_interval_value, interval_unit).format('MMMM DD, YYYY');
	    			var remaining_time = Math.floor(moment(value.time_next_due).diff(new Date()) / 1000); 
	    			value.time_due_soon = moment.unix(remaining_time).subtract(value.time, value.treshold_unit).unix() <= 0 ? "due soon" : "";
	    			if(remaining_time < 0){
	    				value.time_due_soon = "over due";
	    			}
	    			value.time_reminder = value.time_based_interval_value +" "+ interval_unit;


 
	    			var today = moment(new Date()).startOf('day').unix(); 
	    			var renewal_badge = null;
					 
					console.log('didimommyplease',moment.unix(value.due_date), moment.unix(value.due_date).fromNow());

	    			var renewal_text ='Due ' +  moment.unix(value.due_date).fromNow(); //remaining_time
	    			value.renewal_badge = renewal_badge;
	    			value.renewal_text = renewal_text;

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 
	    getDataDueSoon(){ 

			this.isLoading = true;

	    	var service_task_id = this.filter.service_task_id;
	    	if(service_task_id == "all" || service_task_id == null || service_task_id == ""){
	    		service_task_id = ""
	    	}

	    	var vehicle_id = this.filter.vehicle_id;
	    	if(vehicle_id == "all" || vehicle_id == null || vehicle_id == ""){
	    		vehicle_id = "";
	    	}

	    	var furnishing_id = this.filter.furnishing_id;
	    	if(furnishing_id == "all" || furnishing_id == null || furnishing_id == ""){
	    		furnishing_id = "";
	    	}

	    	var equipment_id = this.filter.equipment_id;
	    	if(equipment_id == "all" || equipment_id == null || equipment_id == ""){
	    		equipment_id = "";
	    	}

	    	var asset_type = this.filter.asset_type;
	    	if(asset_type == "all" || asset_type == null || asset_type == ""){
	    		asset_type = "";
	    		vehicle_id = ""
				furnishing_id = ""
				equipment_id = ""

	    	}
	    	else{
	    		if(asset_type == "vehicle"){
	    			furnishing_id = ""
					equipment_id = ""
	    		}
	    		if(asset_type == "furnishing"){
	    			vehicle_id = ""
					equipment_id = ""
	    		}
	    		if(asset_type == "equipment"){
	    			vehicle_id = ""
					furnishing_id = ""
	    		}
	    	}

	    	var vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = "";
	    	}

	    	var date_from = this.filter.date_from;
			if(date_from != ""){
	    		date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var date_to = this.filter.date_to;
	    	if(date_to != ""){
	    		date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		date_to = date_to + 86399; 
	    	}

	    	axios.get(config.api_path+'/serviceReminder?due=due_soon&service_task_id='+service_task_id+'&asset_type='+asset_type+'&vehicle_id='+vehicle_id+'&furnishing_id='+furnishing_id+'&equipment_id='+equipment_id+'&page='+this.currentPageDueSoon+'&limit=20')
	    	.then(response => {
	    		this.dataListDueSoon = response.data; 
	    		this.dataListDueSoon.data = this.dataListDueSoon.data.map((value, index)=>{
	    			value.vehicle_maker = "N/A";
	    			value.vehicle_type = "N/A";
	    			// if(value.vehicle){
	    			// 	value.vehicle_label = value.vehicle.plate_no;
	    			// 	value.vehicle_maker = value.vehicle.maker_model ? value.vehicle.maker_model.setting_name : "N/A"
	    			// 	value.vehicle_type = value.vehicle.vehicle_type ? value.vehicle.vehicle_type.setting_name : "N/A"
	    			// }
	    			// else{
	    			// 	value.vehicle_label = "N/A";
	    			// } 
					value.odometer_label = value.odometer_label ?? '0';

	    			if(value.asset_type == "vehicle"){
	    				value.type = value.vehicle.vehicle_type.setting_name;
	    				value.asset_no_label = value.vehicle.plate_no;
	    				if(value.vehicle.odometer){
	    					if(value.vehicle.odometer.length > 0){
	    						value.odometer_label = value.vehicle.odometer[value.vehicle.odometer.length - 1].reading;
	    					}
	    				}
	    			}

	    			if(value.asset_type == "furnishing"){
	    				value.type = value.furnishing.type;
	    				value.asset_no_label = value.furnishing.asset_no;
	    			}

	    			if(value.asset_type == "equipment"){
	    				value.type = value.equipment.type;
	    				value.asset_no_label = value.equipment.asset_no;
	    			}

	    			value.service_task_id_label = "N/A";
	    			if(value.service_task_id && value.service_task_id != ""){
	    				value.service_task_id_label = value.service_task.setting_name;
	    			} 

	    			value.due_date_label = "N/A";
	    			if(value.due_date && value.due_date != ""){
	    				value.due_date_label = moment.unix(value.due_date).format('MMMM DD, YYYY')
	    			} 
	    			var unit = "days";
	    			if(value.treshold_unit == "weeks"){
	    				unit = "weeks";
	    			}
	    			if(value.treshold_unit == "months"){
	    				unit = "months";
	    			}
	    			if(value.treshold_unit == "years"){
	    				unit = "years";
	    			}

	    			value.notify_date = moment.unix(value.due_date).subtract(value.time, unit).format('MMMM DD, YYYY');


	    			value.meter_next_due = "N/A";
	    			value.meter_reminder = "N/A";
	    			if(value.asset_type == "vehicle"){ 
	    				value.meter_next_due = value.current_odo + value.meter_based_interval;
	    				value.meter_reminder = value.meter_based_interval;
	    			} 

	    			var interval_unit = "days";
	    			if(value.time_based_interval_unit == "weeks"){
	    				interval_unit = "weeks";
	    			}
	    			if(value.time_based_interval_unit == "months"){
	    				interval_unit = "months";
	    			}
	    			if(value.time_based_interval_unit == "years"){
	    				interval_unit = "years";
	    			}

	    			value.meter_next_due = "N/A";
	    			if(value.asset_type == "vehicle"){ 
	    				value.meter_next_due = value.current_odo + value.meter_based_interval;
	    			}
	    			value.time_next_due = "N/A";
	    			value.time_next_due = moment.unix(value.due_date).format('MMMM DD, YYYY');
	    			// value.time_next_due = moment.unix(value.due_date).add(value.time_based_interval_value, interval_unit).format('MMMM DD, YYYY');
	    			var remaining_time = Math.floor(moment(value.time_next_due).diff(new Date()) / 1000); 
	    			value.time_due_soon = moment.unix(remaining_time).subtract(value.time, value.treshold_unit).unix() <= 0 ? "due soon" : "";
	    			if(remaining_time < 0){
	    				value.time_due_soon = "over due";
	    			}
	    			value.time_reminder = value.time_based_interval_value +" "+ interval_unit;


 
	    			var today = moment(new Date()).startOf('day').unix(); 
	    			var renewal_badge = null;
					 
					

	    			var renewal_text ='Due ' +  moment.unix(value.due_date).fromNow(); //remaining_time
	    			value.renewal_badge = renewal_badge;
	    			value.renewal_text = renewal_text;

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 

	    getDataOverDue(){ 

			this.isLoading = true;

	    	var service_task_id = this.filter.service_task_id;
	    	if(service_task_id == "all" || service_task_id == null || service_task_id == ""){
	    		service_task_id = ""
	    	}

	    	var vehicle_id = this.filter.vehicle_id;
	    	if(vehicle_id == "all" || vehicle_id == null || vehicle_id == ""){
	    		vehicle_id = "";
	    	}

	    	var furnishing_id = this.filter.furnishing_id;
	    	if(furnishing_id == "all" || furnishing_id == null || furnishing_id == ""){
	    		furnishing_id = "";
	    	}

	    	var equipment_id = this.filter.equipment_id;
	    	if(equipment_id == "all" || equipment_id == null || equipment_id == ""){
	    		equipment_id = "";
	    	}

	    	var asset_type = this.filter.asset_type;
	    	if(asset_type == "all" || asset_type == null || asset_type == ""){
	    		asset_type = "";
	    		vehicle_id = ""
				furnishing_id = ""
				equipment_id = ""

	    	}
	    	else{
	    		if(asset_type == "vehicle"){
	    			furnishing_id = ""
					equipment_id = ""
	    		}
	    		if(asset_type == "furnishing"){
	    			vehicle_id = ""
					equipment_id = ""
	    		}
	    		if(asset_type == "equipment"){
	    			vehicle_id = ""
					furnishing_id = ""
	    		}
	    	}

	    	var vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = "";
	    	}

	    	var date_from = this.filter.date_from;
			if(date_from != ""){
	    		date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var date_to = this.filter.date_to;
	    	if(date_to != ""){
	    		date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
	    		date_to = date_to + 86399; 
	    	}

	    	axios.get(config.api_path+'/serviceReminder?due=over_due&service_task_id='+service_task_id+'&asset_type='+asset_type+'&vehicle_id='+vehicle_id+'&furnishing_id='+furnishing_id+'&equipment_id='+equipment_id+'&page='+this.currentPageOverDue+'&limit=20')
	    	.then(response => {
	    		this.dataListOverDue = response.data; 
	    		this.dataListOverDue.data = this.dataListOverDue.data.map((value, index)=>{
	    			value.vehicle_maker = "N/A";
	    			value.vehicle_type = "N/A";
	    			// if(value.vehicle){
	    			// 	value.vehicle_label = value.vehicle.plate_no;
	    			// 	value.vehicle_maker = value.vehicle.maker_model ? value.vehicle.maker_model.setting_name : "N/A"
	    			// 	value.vehicle_type = value.vehicle.vehicle_type ? value.vehicle.vehicle_type.setting_name : "N/A"
	    			// }
	    			// else{
	    			// 	value.vehicle_label = "N/A";
	    			// } 
					value.odometer_label = value.odometer_label ?? '0';

	    			if(value.asset_type == "vehicle"){
	    				value.type = value.vehicle.vehicle_type.setting_name;
	    				value.asset_no_label = value.vehicle.plate_no;
	    				if(value.vehicle.odometer){
	    					if(value.vehicle.odometer.length > 0){
	    						value.odometer_label = value.vehicle.odometer[value.vehicle.odometer.length - 1].reading;
	    					}
	    				}
	    			}

	    			if(value.asset_type == "furnishing"){
	    				value.type = value.furnishing.type;
	    				value.asset_no_label = value.furnishing.asset_no;
	    			}

	    			if(value.asset_type == "equipment"){
	    				value.type = value.equipment.type;
	    				value.asset_no_label = value.equipment.asset_no;
	    			}

	    			value.service_task_id_label = "N/A";
	    			if(value.service_task_id && value.service_task_id != ""){
	    				value.service_task_id_label = value.service_task.setting_name;
	    			} 

	    			value.due_date_label = "N/A";
	    			if(value.due_date && value.due_date != ""){
	    				value.due_date_label = moment.unix(value.due_date).format('MMMM DD, YYYY')
	    			} 
	    			var unit = "days";
	    			if(value.treshold_unit == "weeks"){
	    				unit = "weeks";
	    			}
	    			if(value.treshold_unit == "months"){
	    				unit = "months";
	    			}
	    			if(value.treshold_unit == "years"){
	    				unit = "years";
	    			}

	    			value.notify_date = moment.unix(value.due_date).subtract(value.time, unit).format('MMMM DD, YYYY');


	    			value.meter_next_due = "N/A";
	    			value.meter_reminder = "N/A";
	    			if(value.asset_type == "vehicle"){ 
	    				value.meter_next_due = value.current_odo + value.meter_based_interval;
	    				value.meter_reminder = value.meter_based_interval;
	    			} 

	    			var interval_unit = "days";
	    			if(value.time_based_interval_unit == "weeks"){
	    				interval_unit = "weeks";
	    			}
	    			if(value.time_based_interval_unit == "months"){
	    				interval_unit = "months";
	    			}
	    			if(value.time_based_interval_unit == "years"){
	    				interval_unit = "years";
	    			}

	    			value.meter_next_due = "N/A";
	    			if(value.asset_type == "vehicle"){ 
	    				value.meter_next_due = value.current_odo + value.meter_based_interval;
	    			}
	    			value.time_next_due = "N/A";
	    			value.time_next_due = moment.unix(value.due_date).format('MMMM DD, YYYY');
	    			// value.time_next_due = moment.unix(value.due_date).add(value.time_based_interval_value, interval_unit).format('MMMM DD, YYYY');
	    			var remaining_time = Math.floor(moment(value.time_next_due).diff(new Date()) / 1000); 
	    			value.time_due_soon = moment.unix(remaining_time).subtract(value.time, value.treshold_unit).unix() <= 0 ? "due soon" : "";
	    			if(remaining_time < 0){
	    				value.time_due_soon = "over due";
	    			}
	    			value.time_reminder = value.time_based_interval_value +" "+ interval_unit;


 
	    			var today = moment(new Date()).startOf('day').unix(); 
	    			var renewal_badge = null;
					 
					

	    			var renewal_text ='Due ' +  moment.unix(value.due_date).fromNow(); //remaining_time
	    			value.renewal_badge = renewal_badge;
	    			value.renewal_text = renewal_text;

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 

	    updateModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.dataParams.due_date = moment.unix(this.dataParams.due_date).format('MMMM DD, YYYY')
	    	this.formModal = true;
	    	this.editMode = true;
			this.title = "Update User";

	    },

	    viewModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.showViewModal = true; 

	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this Service reminder?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/serviceReminder/'+item.id)
			    	.then(response => {
			    		this.getData();  
			    		this.getDataDueSoon();
			    		this.getDataOverDue();  
			    		Swal.fire({
							title: 'Success!',
							text: "Service reminder successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

	    updateGender(data){
	    	this.dataParams.gender = data;
	    },

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=1000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})
	    	})

	    },

		getVehicleList(){

			axios.get(config.api_path+'/reference/vehicle-list',{
				params:{
				
			}
				})
			.then(response => {
				this.vehicleList = response.data; 
			})

			}, 

	getEquipment(){

			axios.get(config.api_path+'/reference/equipment-list')
			.then(response => {
			this.equipmentList = response.data;
			this.equipmentList.data = this.equipmentList.data.map((value, index)=>{
				value.value = value.id;
				value.label = value.asset_no;
				return value;
			})
			})

			},

	getFurnishing(){

		axios.get(config.api_path+'/reference/furnishing-list')
		.then(response => {
		this.furnishingList = response.data;
		this.furnishingList.data = this.furnishingList.data.map((value, index)=>{
			value.value = value.id;
			value.label = value.asset_no;
			return value;
		})
		})

		},

		getRenewalTask(){

		axios.get(config.api_path+'/reference/settings-list',{
			params:{
			setting_type:'service_task'
			}
			})
		.then(response => {
		this.taskList = response.data; 
		})

		},


	    updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updatePersonnelType(data){
	    	this.dataParams.personnel_type = data;
	    },

	    updateEmploymentType(data){
	    	this.dataParams.employment_type = data;
	    },

	    updateVendor(data){
	    	this.dataParams.vendor_id = data;
	    },

	    updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },

	    vehicleChange(){ 
    		var filter = this.vehicleList.data.filter((value, index)=>{
    			return this.dataParams.vehicle_id == value.id;
    		})[0];

    		if(filter.odo_reading != null){
    			this.dataParams.reading = filter.odo_reading.reading;
    		} 
	    }

 	}
}
</script>
